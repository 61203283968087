@import "../../sass/mixins";

.myProfile {
    display: none;
} 

@media screen and (max-width: 482px) {
    .myProfile {
        display: block;
    }
}