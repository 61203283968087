
.chartFooter__bibliographicReferences {
  margin-top: 10px;
}

.apaReference.accordion .cardHeader {
  padding: 10px;
}

.apaReference.accordion .cardHeader button {
  font-size: 120px;
}

.apaReference.accordion .cardBody {
  padding: 10px;
}

.accordionSummary {

}

.accordionDetails {

}