@import "../../sass/mixins";

/* STYLES district-card */
.section--district-data {
  background: var(--white);
}

.district-card {
  background: var(--primary-color);
  padding: 34px 11px;
  height: 750px;

  
  &__header {
    color: var(--white);
    display: flex;
    justify-content: space-between;
    
    .label {
      margin-right: 6px;
    }
  }
  
  h2 {
    color: var(--white);
    font-size: 26px;
    font-weight: bold;
    margin: 0;
    padding: 0 10px 4px;
  }
  
  &__address {
    display: flex;
    margin-bottom: 4px;
  }
  
  .address-icon {
    border-right: 1px solid var(--complemetary-color);
    margin-right: 10px;
    text-align: center;
    width: 40px;
    svg {
      color: var(--complemetary-color);
    }
  }
  
  &__irn {
    display: inline-block;
    margin: 0 8px;
  }
  
  &__revenue {
    display: inline-block;
    margin: 0 8px;
    
    .label {
      color: #000;
    }
  }
  
  &__info {
    margin: 0 8px;
  }
  
  &__image {
    @include bp-end('mobile-big') {
      display: none;
    }
    height: 120px;
    width: 120px;
    position: absolute;
    right: 20px;
    top: 14px;
    
    img {
      width: 100%;
      height: auto;
    }
    
    &.district-image {
      background: #fff;
      border-radius: 50%;
      height: 140px;
      width: 140px;
      outline: thick solid var(--primary-color);
    }
  }
  
  .label {
    color: var(--complemetary-color);
    display: inline-block;
  }
  
  &__content {
    background: var(--white);
    padding: 17px;
    overflow: hidden;
  }
  
  &__data {
    @include bp-start('mobile-big') {
      display: flex;
    }
    
    .label {
      color: #000;
      min-width: 400px;
    }
    
    @media screen and (max-width: 1305px) and (min-width: 1024px) {
      .label {
        min-width: 300px;
      }
    }
  }
  
  table {
    th {
      font-weight: bold;
    }
    
    thead {
      th {
        border: 0;
        font-weight: bold;
        padding: 0 4px;
      }
      span {
        background: var(--primary-color);
        color: var(--white);
        display: block;
        padding: 4px 8px;
      }
    }
    
    tbody {
      tr {
        &:nth-child(even) {
          background: var(--light-gray);
        }
      }
      td {
        text-align: center;
      }
    }
  }

  // Flip buttons.
  &__actions {
    position: relative;
    height: 0;
    top: 17px;
    left: 26px;
    
    @include bp-start('tablet') {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1120px){
  .district-card{
    padding: 6px;
    height: auto;

    &__content {
      padding: 14px;
    }
  }
}

.flip-btn {
  color: var(--white) !important;
  background-color: var(--complemetary-color) !important;
  position: absolute !important;
  right: 14px;
  top: -80px;
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  padding: 36px !important;
}
