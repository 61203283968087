/**
 * @file
 * Customization styles for Column Components.
 */

.section--enrollment-brackets {
  .highcharts-title {
    font-weight: bold;
  }
  .highcharts-data-table {
    th {
      width: 300px;
    }
    td {
      text-align: center;
    }
  }
}
