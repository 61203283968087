/**
 * @file
 * MAp viewer component styles
 */
@import "../../sass/mixins";

.map-viewer {
  height: 100%;
  position: relative;

  .leaflet-container {
  height: 750px;
  }

  .custom-groups__filters-form & {
    height: auto;
  }

  @media only screen and (max-width: 425px){
    .leaflet-container{
      height: 400px;
    }
  }
}
