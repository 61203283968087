/**
 * @file
 * Customization styles for Slider Components.
 */
@import "../../sass/variables";


// No data menssage
.varRangeSlider {
  & .noDataMessage {
    background: #f1f1f1;
    display: flex;
    margin: auto;
    width: 90%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 10px;
  }

  &Inputs {
    display: flex;
    justify-content: space-between;
  }
}
