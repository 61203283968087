/**
 * @file
 * Save Group Form component styles
 */
@import "../../sass/mixins";

.saveGroupForm {
  padding: 0;
  min-width: 230px;

  .saveGroupFormWrapper {
    .customGroupsFormWrapper {
      .doneButton {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .customGroupsFormItem {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 30px 30px 0 0;
  }

  .saveMessage {
    margin-bottom: 0;
  }

  .success {
    color: #00b050;
  }
  
  .error strong {
    color: #ff0000;
  }

  @media (min-width: 400px) {
    .saveGroupFormWrapper {
      .customGroupsFormWrapper {
        .doneButton {
          padding: 0;
          padding-right: 0px !important;
        }
      }
    }
  }
}