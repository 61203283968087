/**
 * @file
 * insight board styles variables.
 */
@import "functions";
@import "colors";

// Theme colors
$primary-color: theme-color("dark_indigo");
$secondary-color: theme-color("purple");
$complement-color: theme-color("yellow");
$disabled-color: theme-color("gray-lighter");
