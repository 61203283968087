@import "../../sass/mixins";

/*  COMPARISON COMPONENT STYLES */
.comparison-card {
  background-color: var(--white);
  box-shadow: var(--shadow-default);
  margin-bottom: 14px;
  position: relative;

  @include bp-start('desktop') {
    margin-bottom: 0;
    position: relative;
  }

  .label{
    font-size: 12px;
    height: 24px;
  }

  &__title {
    background: var(--primary-color);
    color: var(--white);
    font-size: 18px;
    margin: 0;
    padding: 10px 10px 8px;
    text-align: center;

    @include bp-start('desktop') {
      text-align: left;
    }
  }

  &__timeline {
    button {
      background: var(--primary-color);
      color: var(--white);
      font-size: 11px;
      font-weight: bold;
      display: flex;
      margin: 6px auto 10px;

      &:hover,
      &:focus {
        background: var(--complemetary-color);
        color: var(--white);
        text-decoration: none;
      }

      img {
        background-color: var(--white);
        border-radius: 50%;
        margin-left: 4px;
        transition: .2s linear;
        width: 28px;
      }
    }
  }

  &__form-select {
    flex: 1;

    label {
      display: none;
    }
  }

  &__data {
    padding: 10px;
  }

  &__subtitle {
    border-top: 2px solid var(--light-gray);
    display: flex;
    font-size: 14px;
    padding: 10px 10px 0;
  }

  &__amount {
    @include bp-start('desktop') {
      border-right: 1px dotted var(--gray);
    }
  }
  &__amount,
  &__group-amount {
    font-size: 28px;
    text-align: center;
    padding: 20px 0 10px;

    @include bp-start('desktop') {
      font-size: 22px;
      display: inline-block;
      width: 50%;
    }
  }

  &__average {
    font-size: 14px;
    color: #343A40;
    text-align: justify;
    padding: 0 0 10px;
    @media only screen and (min-width: 768px) {
      height: 42px;
    }	
  }

  .average-value {
    font-weight: bold;

    &.positive {
      color: var(--green);
    }

    &.negative {
      color: red;
    }
  }

  .ranking-group-collapse {
    background: var(--lavander);
    border-radius: 0;
    border: 0;
    padding: 6px 10px !important;
    font-size: 12px;
    text-align: center;
    width: 100%;

    &:hover {
      background: var(--purple-cold);
    }

    @include bp-start('desktop') {
      padding: 12px 10px !important;
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
    }

    & .value {
      font-weight: bold;
    }
  }

  &__toggle-icon {
    margin: -2px 4px 0 0;
  }

  &__toggle-icon.hide{
    transform: rotate(180deg);
  }

  &__ranking-table {
    font-size: 14px;
    margin-bottom: 0;
    thead th {
      font-weight: bold;
    }

    .row--highlighted {
      background: var(--lavander);
    }
  }

  &--no-data {
    background: #f1f1f1;
    display: flex;
    flex-direction: column;
    height: calc(100% - 130px);
    margin: 20px auto;
    width: 90%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: .8rem;
    min-height: 100px;

    p {
      margin-bottom: 0;
    }
  }
}

.timeline__chart {
  border-top: 2px solid var(--light-gray);
  padding-top: 10px;
}

.comparison-select label {
  font-size: 12px;
  margin-bottom: 0;
  text-transform: capitalize;
}

.help-tooltip {
  color: var(--complemetary-color);
  display: flex;
  float: left;
  text-align: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  margin: -1px 4px 0 0;
  opacity: .7;
  cursor: default;
}

.section--data-comparison {
  @include bp-start('tablet') {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  @include bp-start('desktop') {
    grid-template-columns: repeat(3, 1fr);
  }
}
