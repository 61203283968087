/* == Tooltip block ==*/
.infoIcon,
.radarInfoIcon {
  color: var(--complemetary-color);
  font-weight: bold;
  font-size: 14px;
}

.noData {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  font-weight: bold;
  background: #efefef;
  min-height: 200px;
}