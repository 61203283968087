/**
 * @file
 * Customization styles for Pie Chart.
 */
@import "../../sass/variables";

.pie-chart-wrapper {
  position: relative;

  .toggle-table-btn {
    font-weight: bold;
    left: 10px;
    position: absolute;
    bottom: 10px;
    z-index: 1;
  }
}

.highcharts-pie-series {
  & > span {
    width: 296px;
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .legend-name {
    min-width: 50%;
    margin-right: 10px;
  }

  &:nth-child(even) {
    & > span {
      background-color: #f9f9f9;
    }
  }
}

.section--pupil-spending,
.section--student-demographics,
.section--enrollment-brackets {
  .highcharts-data-table {
    padding-bottom: 70px;
    th {
      width: 250px;
    }
    td {
      text-align: center;
    }
  }
}
