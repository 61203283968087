@import "../../../sass/mixins";

.userProfile {

  .profilePhoto {
    align-items: center;
    display: flex;
  }

  .profileName {
    margin: 0 15px;
  }

  .userName {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: capitalize;
  }

  .requestPass {
    color: black;
    border-color: black;
  }

  .groupAdmin {
    display: block;
  }

  .sharedBoards,
  .savedBoards{
    display: block;
    text-transform: capitalize;
  }
}

