@import "../../sass/mixins";

.headerWrapper {
  border: none;
  display: flex;
  height: 72px;
  background: var(--primary-color);
  align-items: center;
  justify-content: space-between;

  @include bp-end('mobile-big') {
    padding-right: 80px;
  }

  .branding {
    margin-left: 12px;

    .brandingLogo {

      @include bp-start('tablet') {
        height:36px;
      }

      @include bp-end('mobile-big') {
        height: 32px;
        margin-right: 64px;
      }

      @include bp-end('mobile') {
        height: 28px;
        margin-right: 32px;
      }
    }
  }

  .pageTitle {
    text-align: center;
    color: var(--white);
    font-size: 1.4rem;

    .district-name {
      font-weight: 400;
    }

    @include bp-end('laptop') {
      font-size: 1.3rem;
    }

    @include bp-end('tablet') {
      font-size: 1.2rem;
    }

    @include bp-end('mobile-big') {
      font-size: 1.1rem;
      margin-right: 64px;
    }

    @include bp-end('mobile') {
      font-size: 0.9rem;
      margin-right: 32px;
    }
  }

  .myProfile {
    @include bp-end('mobile-big') {
      display: none;
    }
  }
}

@media screen and (max-width: 482px)  {

  .pageTitle {
    margin-left: 32px;
  }
  
  .branding__logo {
    display: none;
  }

  }
