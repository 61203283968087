/**
 * @file
 * Customization styles for Radar Components.
 */

.highcharts--radar-chart {
  .to-back{
    z-index: -1;
  }

  .radar-info-icon {
    cursor: pointer;
  }
}
