/**
 * @file
 * Customization styles for Scatter Components.
 */

.section--scatter-data {
  .highcharts-data-table {
    th {
      width: 300px;
    }
    td {
      text-align: center;
    }
  }
}

.highcharts--scatter-chart {
  .to-back{
    z-index: -1;
  }
}
