@import "../../sass/mixins";

.profileWrapper {

  .profile {
    align-items: center;
    display: flex;
    text-decoration: none;
    padding-left: 12px;

    .nickname {
      margin-left: 12px;
      color: white;
      font-weight: 500;
      margin-right: 84px;
    }
  }

  .profileLogo {
    height: 42px;
    width: 42px;
    border-radius: 50%;
  }
}
