/**
 * @file
 * Async wrapper styles.
 */

@import "../../sass/mixins";
@import "../../sass/variables";

.async-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  &__loading {
    align-items: center;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
  }

  .loading {
    &__message {
      color: $primary-color;
      margin-bottom: 10px;

      &-label {
        font-weight: bold;
        font-style: italic;
      }
    }

    &__animation {
      color: $secondary-color;
    }
  }

  // Async wrapper error styles:
  &__error {
    background: $disabled-color;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
  }

  .error {
    &__title {
      color: $secondary-color;
      margin-bottom: 6px;
    }

    &__url {
      font-size: .84rem;
      font-style: italic;
    }

    &__wrong-type {
      font-style: italic;
    }
  }

  p {
    margin: 6px;
  }
}
