/**
 * @file
 * Bug Report Form component styles
 */

@import "../../sass/mixins";

.reportContainer {
  .reportButtons {
    &Wrapper {
      bottom: 0;
      display: flex;
      position: fixed;
      right: 0;
      z-index: 2;
    }

    &Item {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      color: #fff;
      font-weight: bold;

      &:first-child {
        margin-right: 4px;
      }

      &.bugReport {
        background: var(--primary-color);
      }

      &.helpDeskButton {
        background: var(--complemetary-color);
      }
    }
  }
}

#helpReportContainer,
#bugReportContainer {
  bottom: 0;
  display: none;
  position: fixed;
}
