/**
 * @file
 * Customization styles for Histogram Components.
 */

.section--histogram-x,
.section--histogram-y,
.section--histogram-z {
  .highcharts-data-table {
    margin-left: 60px;
  }
}
