:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --gray: #d4d4d4;
  --light-gray: #f7f7f7;
  --green: #00b050;
  --green-cold: #00D7BE;
  --shadow-default: 0 0px 4px #c5c5c5;
  --primary-color: #221349;
  --secondary-color: #F5B335;
  --complemetary-color: #773DBD;
  --purple-cold: #AF96DC;
  --watermelon: #FF5569;
  --lavander: #E1D7F0;
}
@import "./sass/mixins";

/* ===========
 Utility classes
 ==============*/
.clr-prime {
  color: var(--primary-color);
}

.clr-complement {
  color: var(--complemetary-color);
}

.capitalize {
  text-transform: capitalize;
}

* {
  box-sizing: border-box;
}

body {
  background: var(--white);
}

body, #root, #root > div {
  min-height: 100vh;
}

#root > div {
  position: relative;
  padding-bottom: 50px;
}

.layout-wrapper {
  max-width: 1440px;
  margin: auto;
  padding: 30px 0;
  width: 90%;
}
.main-content__top section {
  box-shadow: var(--shadow-default);
  margin-bottom: 24px;
}

.header__bottom {
  background: var(--lavander);
  box-shadow: 0 6px 6px -1px rgba(0, 0, 0, 0.3);
  position: sticky;
  top: 0;
  z-index: 3;
  margin-bottom: 30px;
  padding-bottom: 16px;
  min-height: 40px;

  .layout-wrapper {
    padding: 0;
  }
}

.header__bottom .data-filters__item > label,
.header__bottom .data-filters__item > .label,
.header__bottom .data-filters__item .district-name {
  color: var(--primary-color);
  margin: 6px 0 0;
  padding-left: 4px;
}

.custom-groups .header__bottom .group-counter {
  padding: 30px;
  font-weight: bold;
  text-align: center;
}

.custom-groups .submit-filters {
  float: right;
}

.custom-groups .map-chart {
  position: relative;
}

.custom-groups__loading {
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--complemetary-color);
  font-weight: bold;
  z-index: 2;
  &-message {
    margin-bottom: 10px;
  }
}

.section--data-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.align-container {
  align-items: center;
  margin-top: 20px;
}

@media screen and (min-width: 600px)  {
  .basicFilters {    
    justify-content: center;
  }
}

@media screen and (min-width: 768px)  {
  .basicFilters {
    width: auto !important;
    justify-content: unset;
    margin: 12px;
  }
}

@media screen and (max-width: 680px)  {
  .sharedFilters {
    justify-content: center;
  }
}
.district-filters--hidden {
  .group-counter__wrapper {
    flex-basis: auto;
    width: 100%;
  }
}

.data-filters__item {
  padding: 0 10px;
  margin-bottom: 18px;
}

.data-filters__item label {
 margin-bottom: 4px;
}

.data-filter__group .data-filters__item:not(:first-child) {
  margin-top: 16px;
}

label, .label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

select {
  background-color: var(--white);;
  border-color: var(--gray);;
  min-height: 32px;
  padding: 5px;
  width: 100%;
}

.radio-options {
  display: flex;
  text-align: center;
}

.radio-options input:checked + label {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--white);
}

.radio-options label {
  font-size: 12px;
  padding: 0 10px;
}

.radio-options__item input {
  opacity: 0;
}

.radio-options__item label {
  align-items: center;
  background: var(--white);
  border: 1px solid var(--gray);
  display: flex;
  justify-content: center;
  margin-top: -19px;
  min-height: 32px;
}

.radio-options__item:first-child label {
  border-radius: 10px 0 0 10px;
}

.radio-options__item:nth-child(2) label {
  border-right: 0;
  border-left:  0;
}

.radio-options__item:last-child label {
  border-radius: 0 10px 10px 0;
}

.group-counter__wrapper {
  align-items: center;
  background: none;
  color: var(--primary-color);
  display: flex;
  min-height: 74px;
  flex-direction: column;
  font-weight: bold;
  font-size: 18px;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 0;
}

.group-filters__wrapper {
  align-items: center;
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 18px;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 0;
}

.group-counter__versus {
  margin-bottom: 4px;
  opacity: .7;
  text-align: center;
}

.group-counter__result {
  text-align: center;
}


.data-comparison {
  background-color: var(--light-gray);
  padding: 40px 0;
}

.section--student-demographics,
.section--pupil-spending {
  background: var(--white);
  padding: 10px 0 0;
}

.section--enrollment-brackets,
.section--academic-performance {
  background-color: var(--white);
  padding-top: 10px;
}

.section--student-demographics .extra-data,
.section--pupil-spending .extra-data {
  background: url('./IB_Simbol_DB_RGB.png') no-repeat 96% -18px var(--primary-color);
  background-size: 68px;
  color: var(--white);
  padding: 10px;
  text-align: center;
}

.section.section--extra-filters {
  box-shadow: none;
}

.section--extra-filters .reset-data {
  align-self: center;
  height: 38px;
}
.section--extra-filters .form-select {
  margin-bottom: 40px;
}
.extra-filters {
  margin-bottom: 60px;
  justify-content: flex-end;
}

.extra-filters div {
  margin-right: 5px;
}

.extra-filters div:last-child {
  margin-right: 0;
}

.form-select {
  min-width: 268px;
}

.section--scatter-data button{
  font-size: .85rem;
  font-weight: bold;
}

.highcharts-container:after {
  display: block;
  content: "";
  width: 76px;
  height: 13px;
  background: var(--white);
  position: absolute;
  z-index: 9;
  bottom: 0;
  right: 0;
}

.leaflet-container {
  z-index: 0;
}

.leaflet-container {
  min-height: 400px;
}

.main-content__bottom {
  margin-top: 24px;
}

.no-data {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  font-weight: bold;
  background: #efefef;
  min-height: 200px;
}
/* highcharts data table */
.highcharts-data-table {
  font-size: 12px;
  padding: 14px;
}
.highcharts-data-table th {
  padding-right: 6px;
}


.accordion-item {
  margin-bottom: 10px;
  background: #fff;
}

.accordion-item.card {
  border: 0;
}

.accordion-item.card .card-header {
  border: 1px solid #d4d4d4;
  margin-bottom: 0 !important;
}

.accordion-item.card .card-body {
  border: 1px solid #d4d4d4;
  border-top: 0;
}

.accordion-item .data__label {
  font-weight: bold;
  margin: 8px 0;
}

.accordion-item .data__label::before {
  border-radius: 50%;
  background-color: var(--complemetary-color);
  content: "";
  float: left;
  margin-top: 6px;
  height: 10px;
  width: 10px;
  margin-right: 4px;
  margin-left: -14px;
}

.accordion-item .data__machine-name {
  font-style: italic;
  margin: 8px 0;
}

.accordion-item .item__data {
  padding: 6px 6px 10px;
}

.accordion-item .item__data:not(:last-child) {
  border-bottom: 1px dotted #d4d4d4;
}

.button__toggle-menu {
  position: fixed;
  background-color: transparent;
  border: none;
  z-index: 6;
}

.button__toggle-menu {
  transition: 400ms;
  cursor: pointer;
}

.button__toggle-menu.open {
  svg {
    color: var(--white);
  }
}
.button__toggle-menu.open.on-scroll {
  background: var(--primary-color);
  box-shadow: 0 0 5px 2px #c9c9c9;
  right: 10px;
  top: 0;
}

.button__toggle-menu.open {
  right: 22px;
  top: 10px;
}

.button__toggle-menu.close {
  right: 207px;
  top: 0;
}

.button__toggle-menu.close:hover {
  opacity: 1 !important;
}

.main-nav__menu {
  background: var(--primary-color);
  border-left: 4px solid var(--complemetary-color);
  height: 100vh;
  padding: 70px 0 20px;
  position: fixed;
  right:  0;
  top: 0;
  transition: 350ms;
  width: 250px;
  z-index: 5;

  .profile {
    padding: 16px;
    align-items: center;
    text-decoration: none;
      
    .profile__logo {
      height: 42px;
      width: 42px;
      border-radius: 50%;
    }

    .nickname {
      margin-left: 12px;
      color: white;
      font-weight: 500;
      margin-right: 84px;
    }

    @include bp-start('mobile-big') {
      display: none;
    }

    @include bp-end('mobile-big') {
      display: flex;
    }
    
  }
}

.main-nav__menu.menu--hide {
  right: -100%;
}

.main-nav__link {
  color: var(--white);
  display: block;
  padding: 15px 26px;
}

.main-nav__link.selected {
  background-color: var(--complemetary-color);
}

.main-nav__link:hover {
  background-color: var(--complemetary-color);
  color: var(--white);
  text-decoration: none;
}

.main-nav__overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  z-index: 4;
}

/* == Inline Help block ==*/
.inline-help {
  border-top: 1px solid #130b38;
  font-size: 12px;
  padding: 10px;
}
.inline-help label {
  color: var(--white);
}
.inline-help__definition {
  background-color: var(--white);
  margin-top: 10px;
  padding: 10px 8px;
  position: relative;
}
.inline-help__definition::before {
  content: "";
  display: block;
  border: 8px solid;
  border-color: transparent;
  border-bottom-color: var(--white);
  width: 8px;
  position: absolute;
  top: -16px;
}
.inline-help .form-select {
  min-width: auto;
}

.extra-filters__actions {
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
}

/* == Tooltip block ==*/
.info-icon,
.radar-info-icon {
  color: var(--complemetary-color);
  font-weight: bold;
  font-size: 14px;
}

#label-tooltip {
  background: rgb(0, 0, 0);
  border-radius: 4px;
  font-size: 11px;
  color: #fff;
  padding: 10px;
  position: absolute;
}

#label-tooltip::before {
  content:'';
  display: block;
  border: 8px solid transparent;
  border-bottom-color: #000;
  position: absolute;
  top: -16px;
}

.chart-footer-data {
  font-size: 12px;
  padding: 10px 16px;
}

.dashboard-page.custom-groups .horizontal-slider{
  padding-top: 14px;
  min-height: 40px;
  margin: 10px auto;
}

.dashboard-page.custom-groups  .var-option {
  background: var(--light-gray);
  padding: 4px 17px;
  margin-bottom: 4px;
  position: relative;
}
.dashboard-page.custom-groups .custom-groups__filters-form {
  margin-bottom: 40px;
}

.dashboard-page.custom-groups .custom-groups__filters .form-advice {
  align-items: center;
  background: var(--light-gray);
  display: flex;
  height: 100%;
  font-weight: bold;
  justify-content: center;
}

.var-option__color-indicator {
  width: 50px;
  height: 10px;
  display: inline-block;
  margin-right: 6px;
  border-radius: 5px;
}
.dashboard-page.custom-groups  .var-option label{
  font-size: .8rem;
  margin-bottom: 0;
}

.dashboard-page.custom-groups  .var-option label{
  font-size: .8rem;
  margin-bottom: 0;
}

.dashboard-page.custom-groups .remove-filter {
  border-radius: 50%;
  box-shadow: none;
  min-width: 22px;
  height: 20px;
  padding: 0;
  position: absolute;
  right: 4px;
  margin: 5px;
}

.dashboard-page.custom-groups .parallel-chart {
  margin-top: 20px;
}

.dashboard-page.custom-groups .custom-groups__actions{
  background: #221349;
}

.dashboard-page.custom-groups .variable-group .form-select {
  margin-bottom: 10px;
}

.custom-groups__form {
  margin: auto;
  max-width: 768px;
  padding: 20px 0;
  overflow: hidden;
  width: 100%;
}

.custom-groups__form .form-submit {
 margin: 20px auto;
  display: block;
}

.data-filter__group {
  margin-top: 10px;
}

.custom-groups__form  .form-field {
  background: #fff;
  width: 100%;
}

.section--data-filters .data-filters__collapse-button {
  align-self: flex-start;
  background: var(--primary-color);
  border-radius: 0 0 4px 4px;
  color: var(--white);
  padding: 5px !important;

  &:hover {
    background: var(--complemetary-color);
  }
}

.collapse-button-text {
  font-size: 12px;
  text-transform: capitalize;
  padding-right: 6px;
  display: flex;
  align-items: center
}

.custom-groups__save-message p {
  margin-bottom: 0;
}

.custom-groups__save-message .success {
  color: #00b050;
}

.custom-groups__save-message .error strong {
  color: #ff0000;
}

.custom-groups .form__message {
  border: 1px dashed #bfbfbf;
  padding: 6px;
  font-size: 14px;
  background: #efefef;
}

.custom-groups .form__message ul {
  margin-bottom: 0;
}

span.filter__counter {
  float: right;
  padding-right: 20px;
  font-size: 12px;
  font-weight: bold;
  padding-top: 4px;
}

.login-form__wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 50%;
  transform: translateY(-50%);
}

.login-form__wrapper .branding {
  margin-bottom: 20px;
  position: relative;
  height: auto;
  background: var(--primary-color);
  padding: 20px 10px;
}

.slides-info {
  font-size: 14px;
  text-align: right;
  svg {
    color: var(--complemetary-color);
  }
}

.district-explorer .chart-options {
  display: flex;
}

.district-explorer .z-axis-description {
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 8px;

  svg {
    color: var(--complemetary-color);
    font-size: 16px;
    margin-right: 2px;
  }
}

.accounts-table {
  .MuiBox-root {
    th, td {
      white-space: normal;
      word-wrap: break-word;
      max-width: 200px;
    }
  }

  & > .MuiPaper-root {
    box-shadow: none;
    padding: 0 8px;
  }

  .MuiTableCell-footer {
    border: 0;
  }

  .table-wrapper {
    background: #fbfbfb;
  }
}

/*
//--------------
 Medium styles
 */
@media only screen and (min-width: 768px)  {
  .section--data-filters {
    padding: 12px;
  }
  .filter-district, .filter-group-type {
    flex-grow: 2;
  }
  .filter-group-type-options, .group-counter__wrapper {
    flex-basis: 100%;
    background: none;
  }

  .data-filters__collapse-button {
    margin-right: 0;
    margin-left: auto;
  }
  .data-filter__collapse {
    width: 100% !important;
  }

  .data-filter__group .data-filters__item:not(:first-child) {
    margin-top: 0;
  }

  .data-filters__item label {
    margin-bottom: 0;
  }

  .data-filter__collapse .data-filter__group .filter-group-type-options {
    margin-top: 16px;
  }

  .district-filters--hidden {
    .data-filters__collapse-button {
      position: absolute;
    }
  }
}

.z-axis-tooltip {
  position: absolute;
  top: -4px;
}
.z-axis-tooltip + label {
  padding-left: 20px;
}
.z-data-filter {
  position: relative;
}

/*
//--------------
 Regular styles
 */
@media only screen and (min-width: 1024px)  {
  .filter-district, .filter-group-type {
    flex-grow: initial;
  }
  .filter-group-type-options {
    flex-basis: initial;
    margin-top: 8px;
  }

  .main-content__top {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 24px;
  }

  .main-content__top section {
    margin-bottom: 0;
  }

  .section--district-data {
    grid-column: 1 / 4;
  }

  .section--district-map {
    grid-column: 4 / 7;
  }

  .section--student-demographics,
  .section--enrollment-brackets {
    grid-column: 1 / 4;
  }
  .section--pupil-spending,
  .section--academic-performance {
    grid-column: 4 / 7;
  }

  .section--extra-filters {
    grid-column: 4 / 7;
  }

  .district-explorer .section--district-map {
    grid-column: 1 / 4;
  }

  .section--scatter-data,
  [class^="section section--histogram"] {
    grid-column: 1 / 7;
  }

  .page-glossary .main-content__top {
    grid-template-columns: repeat(2, 1fr);
  }

  .dashboard-page.custom-groups .funnel-chart {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }
  .dashboard-page.custom-groups  .variable-group {
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }

  .dashboard-page.custom-groups .custom-groups__filters .data-filter__group {
    width: 50%;
  }

  .custom-groups__form .form-item {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 30px 30px 0 0;
  }
}

/*
//--------------
 Wide styles
 */
 @media only screen and (min-width: 1280px) {
  .MuiGrid-spacing-xs-2 > .MuiGrid-item{
    padding-right: 52px !important;
  }
 }

@media only screen and (min-width: 1366px)  {
  /* .header__bottom label {
     color: var(--white);
  } */

  .header__bottom .radio-options input:checked + label {
    background-color: var(--complemetary-color);
    border-color: var(--complemetary-color);
  }

  .header__bottom .radio-options {
    margin-top: 8px;
  }

  .group-counter__wrapper {
    background: none;
    flex-basis: 100%;
  }

  .filter-group-type-options label {
    color: var(--white);
  }

  .district-explorer .section--district-map {
    grid-column: 1 / 3;
  }

  .section--extra-filters {
    justify-content: flex-end;
    grid-column: 3 / 7;
  }

  .section--extra-filters .data-filters__item{
    display: flex;
    flex-wrap: wrap;
  }

  .section--extra-filters .data-filters__item label{
    width: 100%;
  }
  .section--extra-filters .data-filters__item .form-select,
  .section--extra-filters .data-filters__item .var__range-slider {
    width: 50%;
  }
  .section--extra-filters .data-filters__item .var__range-slider {
    padding: 0 0 0 40px;
  }
  .section--histogram-x {
    grid-column: 1 / 3;
  }

  .section--histogram-y {
    grid-column: 3 / 5;
  }

  .section--histogram-z {
    grid-column: 5 / 7;
  }
  .extra-filters__actions {
    padding-top: 0;
  }
  .section--extra-filters .data-filters__item {
    margin-bottom: 0;
  }

  .data-filter__collapse {
    width: 70% !important;
  }

  .data-filter__collapse .data-filter__group .filter-group-type-options {
    margin-top: 0;
  }

  .custom-groups .group-counter__wrapper {
    min-height: auto;
    width: 100%;
  }

  .custom-groups .header__bottom .group-counter {
    padding: 0;
  }

  .dashboard-page.custom-groups .data-filter__collapse {
    width: 50% !important;
  }
}

.error_message {
  color: red;
}

.main-nav__menu .log-btn {
  display: block;
  margin: 4px auto;
}

//Glosary
.glossary__card-items {
  margin: 20px;
  margin-top: 10px;
}

.glossary__description {
  margin: 10px;
  text-align: justify;
}

.glossary__row-color {
  &:nth-of-type(odd) {
    background: var(--lavander);
  }
}

.card__row-color {
  &:nth-of-type(odd) {
    background: var(--lavander);
  }
}

.error-container{
  text-align: center;
}

.error-title{
  margin-bottom: 0;
  font-size: 7rem;
}

/**
 * @TODO Add this styles via material table config and remove this.
 */
 .Table-button {
    border-radius: 5px;
    margin-left: 10px;
    background: var(--primary-color);
    color: #fff;
    padding: 2px 8px 2px 4px;
    font-size: .85rem ;
    font-weight: bold;
    display: flex;
    align-items: center;
    &:hover {
      background: var(--complemetary-color);
    }
  }



.groups-buttons {
  border-bottom: 4px solid var(--gray);
  margin-bottom: 12px;

  &__item {
    border-radius: 0 !important;
    margin-bottom: -4px !important;
    border-bottom: 4px solid var(--gray) !important;

    &.active {
      background-color: rgba(0, 0, 0, 0.04);
      color: var(--complemetary-color);
      border-bottom: 4px solid var(--complemetary-color) !important;
    }
  }
}

//Election history table styles
@media only screen and (max-width: 1412px){
  .MuiDataGrid-root .MuiDataGrid-cell {
    line-height: 17px !important;
    font-size: smaller;
    margin: 5px 0;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    line-height: 11px;
    font-size: smaller;
  }
}

#menu-shared {
  .MuiAutocomplete-root .MuiAutocomplete-tag {
    background-color: #808080;
    color: #ffffff;
  }
}

.editButtonItem {
  margin: 0px 10px 10px 10px !important;
}

.district-filters--active {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 768px)  {
  .district-filters--active {
    flex-direction: column;
  }
}

.leaflet-tooltip{
  font-size: .7rem;
  width: 280px;
  white-space: normal;
}

.RangeSlider .MuiSlider-mark:hover + .MuiSlider-markLabel {
  background: rgba(0, 0, 0, 0.9);
    color: #fff;
    z-index: 2;
    padding: 3px 6px;
}
